import React, { useState } from 'react';
import Img from 'gatsby-image';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import '../styles/db-gallery.css';

const DbGallery = ({ data, images, thumbs }) => {
  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const prevIndex = (index - 1 + images.length) % images.length;
  const nextIndex = (index + 1) % images.length;

  console.log('Index ', index);

  return (
    <div className="db-gallery">
      <div className="gal-container">
        {thumbs.map((item, thumbIndex) => (
          <div
            key={item.id}
            className="thumb"
            onClick={(e) => {
              setIndex(thumbIndex);
              setIsOpen(!isOpen);
            }}
          >
            <Img
              fluid={{
                ...item.fluid,
              }}
              className="thumbImg"
              placeholderStyle={{ backgroundColor: 'red' }}
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[index]}
          nextSrc={images[nextIndex]}
          prevSrc={images[prevIndex]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setIndex(prevIndex)}
          onMoveNextRequest={() => setIndex(nextIndex)}
          imageLoadErrorMessage="Das Bild konnte nicht geladen werden"
          nextLabel="Nächstes Bild"
          prevLabel="Vorheriges Bild"
          zoomInLabel="Zoom in"
          zoomOutLabel="Zoom out"
          closeLabel="Schließen"
        />
      )}
    </div >
  )
}

export default DbGallery;