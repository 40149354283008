import React from 'react';
import DbGallery from '../components/dbGallery';
import { graphql } from "gatsby"
import Layout from '../components/layout';
import SEO from "../components/seo"
import '../styles/main.css'

const Motive = ({ data }) => {
  const thumbnails = data.images.edges.map(item => item.node.thumb)
  const fullSize = data.images.edges.map(item => item.node.full.fluid.src)

  return (
    <Layout>
      <SEO title="Motive" />
      <h1 className="heading heading--h1" hidden>Motive</h1>
      <DbGallery data={data} images={fullSize} thumbs={thumbnails} full={fullSize} />
    </Layout>
  )
}

export const query = graphql`
  query {
    images: allFile(filter: {relativeDirectory: {glob: "motiv*"}}) {
      edges {
        node {
          id
          thumb: childImageSharp {
            id
            fluid(maxWidth: 300, maxHeight: 200) {
            #   originalImg
            #   originalName
            ...GatsbyImageSharpFluid,
            }
          }
          full: childImageSharp {
            id
            fluid(
              maxWidth: 1200, 
              maxHeight: 800,
              quality: 85
              ) {
            #   originalImg
            #   originalName
            ...GatsbyImageSharpFluid,
            }
          }
        }
      }
    }
  }
`

export default Motive;